import React from 'react';
import { TuinInhoud } from './TuinInhoud';

import styled from "styled-components";
import onkruid from "./images/onkruid.svg";
import {PlantSource} from "./PlantSource";

type TuinBlockjeProps = {
    inhoud: TuinInhoud,
    addSelection : (flag: boolean, x : number, y : number) => void;
    x : number,
    y : number,
    blocksize : number, language : string,
    gardenIndex : number
}

interface TuinBlockjeState {
    isChecked: boolean
}

const MySelection = styled.div`
 background-color:#28a745;
 display: inline-block;
 position:relative;
 border:1px;
 border-color:black;
 margin:1px;
`;

const SideCheckbox = styled.input`
    top: 0;
    left:0;
    position: absolute;
    width: 15px;
    display: inline-block;
    font-size:16px;
    margin: 0;
    padding: 0;
    vertical-align: top;
    float: left;
`;


const showIcon = (type: string, x: number, y: number, size : number, language: string, gardenIndex : number) => {
    let result: JSX.Element;
    try {
        let src = PlantSource.getPlantByName(type, language).src;
        result = <img data-cy={"img"+type.replaceAll(" ", "-")+x+"-"+y+'-'+gardenIndex} src={src} width={size+'px'} height={size+'px'}  alt={type}/>
    } catch (e) {
        result = <img data-cy={"img"+type+x+"-"+y+'-'+gardenIndex} src={onkruid} width={size+'px'} height={size+'px'} alt={type}/>
    }
    return result;
};



export class TuinBlokje extends React.Component<TuinBlockjeProps, TuinBlockjeState>
{

    constructor(props : any) {
        super(props);
        this.changeCheckbox = this.changeCheckbox.bind(this);
        this.state = {
            isChecked: false,
        };
        this.clearCheckbox = this.clearCheckbox.bind(this);
    }
    componentDidMount() {
        window.addEventListener('clearcheckboxes', this.clearCheckbox);
    }
    componentWillUnmount() {
        window.removeEventListener('clearcheckboxes', this.clearCheckbox);
    }

    clearCheckbox =  () => {
        if (this.state.isChecked) {
            this.setState({
                isChecked: !this.state.isChecked,
            });
            this.props.addSelection(false, this.props.x, this.props.y);
        }
    }

    changeCheckbox(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            isChecked: !this.state.isChecked,
        });
        this.props.addSelection(!this.state.isChecked, this.props.x, this.props.y);

    }

    render() {
        return <MySelection>
           <SideCheckbox
               width={this.props.blocksize}
               height={this.props.blocksize}
               data-cy={"check"+this.props.x + "-" +this.props.y + '-' + this.props.gardenIndex}
               checked={this.state.isChecked}
               type={"checkbox"}
               onChange={this.changeCheckbox}
           />
       { showIcon(this.props.inhoud?.type, this.props.x, this.props.y, this.props.blocksize, this.props.language, this.props.gardenIndex) }
       </MySelection>;
   }
}

export default TuinBlokje;
