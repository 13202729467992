import React from 'react';
import {TuinInhoudMap} from "./TuinInhoudMap";
import {GardenWeekOverview} from "./GardenWeekOverview";
import {PlantenSelector} from "./PlantenSelector";
import {Point} from "./Point";
import {DirectPanel} from "./DirectPanel";
import {Translater} from "./Translater";
import styled from "styled-components";
import {AddGarden} from "./AddGarden";

interface IProps {
}

interface IState {
    tuinInhoudMap : TuinInhoudMap[]
    breedte: number,
    hoogte: number,
    selectedBlocks : Point[],
    selectedWeek : number,
    show:boolean,
    showReset:boolean,
    year : number,
    blocksizeincm : number
    language : string

}

const GardensDiv = styled.div`
    padding-top: 20px;
    padding-bottom: 20px;
`;

const AddGardenDiv = styled.div`
    padding-top: 2px;
    padding-bottom: 5px;
`;


class Tuin extends React.Component<IProps, IState> {

    constructor(props: any) {
        super(props);
        this.state = {
            tuinInhoudMap : [new TuinInhoudMap(10,2)],
            breedte: 10,
            hoogte: 2,
            selectedBlocks : [],
            selectedWeek: 1,
            show: false,
            showReset: true,
            year : 2021, blocksizeincm : 100,
            language : "en"
        }

        this.herbouwBord = this.herbouwBord.bind(this);
        this.setSelectedCrop = this.setSelectedCrop.bind(this);
        this.setSelection = this.setSelection.bind(this);
        this.setInhoudMap = this.setInhoudMap.bind(this);
        this.addGardenData = this.addGardenData.bind(this);
        this.removeGarden = this.removeGarden.bind(this);
    }

    addGardenData(breedte : number, hoogte : number) : void
    {
        let nieuwBord = new TuinInhoudMap(breedte, hoogte);
        let maps = this.state.tuinInhoudMap;
        maps.push(nieuwBord);
        this.setState({tuinInhoudMap: maps, year:this.state.year, blocksizeincm: this.state.blocksizeincm, language:this.state.language})
    }

    herbouwBord(breedte : number, hoogte:number, year: number, blocksizeincm : number, language: string )
    {
        let nieuwBord = new TuinInhoudMap(breedte, hoogte);
        this.setState({tuinInhoudMap: [nieuwBord], year:year, blocksizeincm: blocksizeincm, language:language})
    }

    setSelectedCrop(crop:string, weekduration:number) {
        let ar = this.state.tuinInhoudMap;
        for (let point of this.state.selectedBlocks) {
            ar[point.gardenIndex] = this.state.tuinInhoudMap[point.gardenIndex].withValuesAdjusted(this.state.selectedWeek, crop, point.x, point.y, weekduration);
        }
        this.setState({tuinInhoudMap:ar});
    }

    setSelection(points: Point[], gardenIndex: number) {
        //don't overwrite points for other gardens.
        let pointsForOtherGardens = this.state.selectedBlocks.filter(p => p.gardenIndex !== gardenIndex);
        this.setState({selectedBlocks: [...points, ...pointsForOtherGardens]});
    }


    setInhoudMap(nieuwBord: TuinInhoudMap[]) : void
    {
        this.setState({tuinInhoudMap: nieuwBord});
    }

    removeGarden(gardenIndex : number) : void
    {
        const maps = this.state.tuinInhoudMap;
        maps.splice(gardenIndex, 1);

        this.setState({tuinInhoudMap: maps});
    }

    clearCheckboxes():void
    {

    }

    render() {
        return <div data-cy={'garden'} id={"garden"}>
            <DirectPanel language={this.state.language} tuinInhoudMap={this.state.tuinInhoudMap} setGarden={this.setInhoudMap} gardenAsText={"["+this.state.tuinInhoudMap.map(m => m.asJson()).join(",")+"]"} herbouwBord={this.herbouwBord}/>
            <h1> {Translater.translate("garden_on_year", this.state.language)} {this.state.year}</h1>
            <GardensDiv>
                {this.state.tuinInhoudMap.map((map, index) =>
                    <GardenWeekOverview removeGarden={this.removeGarden} key={index} language={this.state.language} setSelection={this.setSelection} selectedWeek={this.state.selectedWeek} tuinInhoudMap={map} tuinInhoudMapIndex={index}/>
                )}
            </GardensDiv>
            <AddGardenDiv>
                <AddGarden blocksize={this.state.blocksizeincm} addGardenData={this.addGardenData} language={this.state.language}/>
            </AddGardenDiv>
            <PlantenSelector language={this.state.language} blockSize={this.state.blocksizeincm} setSelectedCrop={this.setSelectedCrop} selectedMonthIndex={this.state.selectedWeek}/>
        </div>
    }
}

export default Tuin;
