import React from 'react';
import './App.css';
import Tuin from './Tuin';
import Container from 'react-bootstrap/Container';
import styled from "styled-components";
import reactLogo from './images/leaf.svg';
import TutorialComponent from "./TutorialComponent";


const Contains = styled.div`
background : rgba(255,255,255,0.9);
border-radius: 25px;
padding:20px;
margin: 20px;
`;

const Footer = styled.div`
padding-bottom: 230px;
`;

const Branding = styled.div`
background : rgba(0,0,0,0.9);
border-radius: 10px;
height:50px;
color: white;
display: flex;
justify-content: center;
padding: 10px;
`;

const BrandingRight = styled.div`
justify-content: flex-end
`;
const TutorialRight = styled.div`
justify-content: flex-end
`;


const Span = styled.span`
width : 10px;
`;


function App() {
  return (
      <Container>
          <Branding>
              <img src={reactLogo} alt={"logo"} width={'30px'} height={'30px'}/><Span/><h3>VeggiePatch</h3>
              &nbsp;<TutorialRight><TutorialComponent/></TutorialRight>
          </Branding>

          <BrandingRight>
              <a href="https://www.buymeacoffee.com/Denshade" rel="noreferrer" target={"_blank"}><img src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png" alt="Buy Me A Coffee" height={"30px"} width={"108px"}/></a>
          </BrandingRight>
            <Contains className="App">
              <Tuin/>
            </Contains>
          <Footer/>
        </Container>
  );
}

export default App;
