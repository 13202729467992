import React, {ChangeEvent} from "react";
import {Button, Modal} from "react-bootstrap";
import InputNumber from "rc-input-number";
import {Translater} from "./Translater";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


interface ResetGardenProps {
    herbouwBord : (breedte: number, hoogte: number, year: number, blocksizecm : number, language: string) => void
}

interface ResetGardenState {
    showReset : boolean,
    breedte : number,
    hoogte : number,
    year : number,
    blocksizecm : number,
    language : string
}

export class ResetGarden extends React.Component<ResetGardenProps, ResetGardenState>
{
    constructor(props: ResetGardenProps) {
        super(props);
        this.state = {
            showReset: true,
            breedte : 2000,
            hoogte : 200,
            year : 2023,
            blocksizecm: 100, language : "en"
        }
        this.handleCloseReset = this.handleCloseReset.bind(this);
        this.onChangeBreedte = this.onChangeBreedte.bind(this);
        this.onChangeHoogte = this.onChangeHoogte.bind(this);
        this.herbouwBord = this.herbouwBord.bind(this);
        this.handleShowReset = this.handleShowReset.bind(this);
        this.onChangeYear = this.onChangeYear.bind(this);
        this.onChangeBlockSize = this.onChangeBlockSize.bind(this);
        this.onChangeLanguage = this.onChangeLanguage.bind(this);
        }

    onChangeLanguage(value : ChangeEvent<HTMLSelectElement>) {
        this.setState({language : value.target.value});
    }

    handleCloseReset() {
        this.setState({showReset:false});
    }

    onChangeBreedte (value : string | number | undefined) {
        if (typeof value == "number") {
            this.setState({ breedte : value });
        }
        if (typeof value == "string") {
            this.setState({ breedte : parseInt(value) });
        }
    }

    onChangeHoogte (value : string | number | undefined) {
        if (typeof value == "number") {
            this.setState({ hoogte : value });
        }
        if (typeof value == "string") {
            this.setState({ hoogte : parseInt(value) });
        }
    }

    onChangeYear  (value : string | number | undefined) {
        if (typeof value == "number") {
            this.setState({ year : value });
        }
        if (typeof value == "string") {
            this.setState({ year : parseInt(value) });
        }
    }
    onChangeBlockSize  (value : string | number | undefined) {
        if (typeof value == "number") {
            this.setState({ blocksizecm : value });
        }
        if (typeof value == "string") {
            this.setState({ blocksizecm : parseInt(value) });
        }
    }

    herbouwBord() {
        this.props.herbouwBord(Math.ceil(this.state.breedte/100), Math.ceil(this.state.hoogte/100), this.state.year, this.state.blocksizecm, this.state.language);
        this.handleCloseReset();
    }
    handleShowReset() {
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure you want to reset the entire garden? You will lose all changes.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.setState({showReset:true})
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    }
    render() {
        return <React.Fragment><Modal animation={false} show={this.state.showReset} aria-labelledby="contained-modal-title-vcenter" onHide={this.handleCloseReset}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    { Translater.translate("tuinplan_maken", this.state.language) }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid">
                <label>{ Translater.translate("taal", this.state.language) }: &nbsp;
                <select data-cy={"languagepicker"} onChange={this.onChangeLanguage}>
                    <option value={"en"}>English</option>
                    <option value={"nl"}>Nederlands</option>
                </select></label><br/>
                <label>{ Translater.translate("breedte", this.state.language) } <InputNumber
                    data-cy={"breedte"}
                    value={this.state.breedte}
                    min={1}
                    max={26800}
                    onChange={this.onChangeBreedte}/></label> &nbsp;
                <label>{ Translater.translate("hoogte", this.state.language) } <InputNumber
                    data-cy={"hoogte"}
                    value={this.state.hoogte}
                    min={1}
                    max={26800}
                    onChange={this.onChangeHoogte}/></label><br/>
                <label>{ Translater.translate("blocksize", this.state.language) } <InputNumber
                    data-cy={"blocksize"}
                    value={this.state.blocksizecm}
                    min={10}
                    max={1000}
                    onChange={this.onChangeBlockSize}/></label> &nbsp;
                <label>{ Translater.translate("jaar", this.state.language) } <InputNumber
                    data-cy={"year"}
                    value={this.state.year}
                    min={2020}
                    max={2100}
                    onChange={this.onChangeYear}/></label><br/>
            </Modal.Body>
            <Modal.Footer>
                <Button data-cy={"apply-reset"} onClick={this.herbouwBord}>{ Translater.translate("apply", this.state.language) }</Button>
                <Button onClick={this.handleCloseReset} data-cy={"closeDetailsReset"}>{ Translater.translate("close", this.state.language) }</Button>
            </Modal.Footer>
        </Modal>
        <Button className={"btn btn-default"} data-cy="reset-btn" onClick={this.handleShowReset}>{Translater.translate('reset', this.state.language)}</Button>
        </React.Fragment>
    }
}