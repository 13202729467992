import React from "react";
import {Button} from "react-bootstrap";
import styled from "styled-components";
import {TuinInhoudMap} from "./TuinInhoudMap";
import {ResetGarden} from "./ResetGarden";
import {Translater} from "./Translater";

const PlantSelectorContainer = styled.div`
    background-color: rgba(94, 123, 145, 0.5);
    border-radius: 5px;
`;

const DownloadAnchor = styled.a`
color: white;
`;

const FilePicker = styled.input`
margin : 10px;
`;


interface DirectPanelProps {
    tuinInhoudMap: TuinInhoudMap[],
    gardenAsText : string,
    setGarden : (map : TuinInhoudMap[]) => void, herbouwBord : (breedte : number, hoogte:number, year: number, blocksizeincm : number, language: string ) => void,
    language : string
}

interface DirectPanelState {
    selectedFile : any
}


export class DirectPanel extends React.PureComponent<DirectPanelProps, DirectPanelState>
{
    private readonly fileInput : React.RefObject<HTMLInputElement>;
    constructor(props: DirectPanelProps) {
        super(props);
        this.state = {
            selectedFile : null
        }
        this.fileInput = React.createRef();
        this.loadData = this.loadData.bind(this);
    }

    loadData() {
        this.fileInput.current?.files?.item(0)?.text().then((v) => {
            let maps = [];
            let mapJsons = JSON.parse(v);
            for (let i = 0; i < mapJsons.length; i++) {
                let oneJson = mapJsons[i];
                maps.push(TuinInhoudMap.fromString(JSON.stringify(oneJson)));
            }
            this.props.setGarden(maps);
        });
    }

    render() {
        return <footer className="bg-light text-center text-lg-start">
            <PlantSelectorContainer>
                <ResetGarden herbouwBord={this.props.herbouwBord}/> &nbsp;
                <FilePicker className={"btn btn-primary"} ref={this.fileInput} onChange={this.loadData} type={"file"}/>
                <Button className={"btn btn-default"} data-cy={"save-button"}><DownloadAnchor href={"data:application/octet-stream," + this.props.gardenAsText} download={"garden.json"}>{Translater.translate("save", this.props.language)}</DownloadAnchor></Button>
            </PlantSelectorContainer>
        </footer>
    }
}