import {TuinInhoudMap} from "./TuinInhoudMap";
import React from "react";
import TuinBlokje from "./TuinBlokje";
import {Point} from "./Point";
import styled from "styled-components";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

interface IGardenWeekOverviewProps {
    tuinInhoudMap: TuinInhoudMap
    selectedWeek: number;
    setSelection: (points : Point[], gardenIndex : number) => void,
    language : string, tuinInhoudMapIndex : number,
    removeGarden : (gardenNumber : number) => void
}

interface IGardenWeekOverviewState {
    selectedX: number,
    selectedY: number,
    show: boolean,
    selection : boolean[],
    containerwidth : number
}

const CenteredGardenView = styled.div`
    padding : 10px; 
    `;
const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row-reverse;
`;

export class GardenWeekOverview extends React.Component<IGardenWeekOverviewProps, IGardenWeekOverviewState> {
    private readonly myRef : React.RefObject<HTMLDivElement>;

    constructor(props: any) {
        super(props);
        this.state = {
            show: false,
            selectedX: 0,
            selectedY: 0,
            selection : Array<boolean>(props.tuinInhoudMap.width*props.tuinInhoudMap.height),
            containerwidth : 1000
        }
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.addSelection = this.addSelection.bind(this);
        this.clickRemove = this.clickRemove.bind(this);
        this.myRef = React.createRef<HTMLDivElement>();
    }

    addSelection(flag: boolean, x : number, y : number) : void {
        let selectionAdjusted = this.state.selection;
        let width = this.props.tuinInhoudMap.width;
        selectionAdjusted[(y - 1)*width + x-1] = flag;
        this.setState({ selection : selectionAdjusted});
        this.props.setSelection(
            selectionAdjusted
                .map((o, i) =>
                     o ? new Point(i%width + 1, Math.floor(i/width) + 1, this.props.tuinInhoudMapIndex):new Point(-3, -3, this.props.tuinInhoudMapIndex))
                .filter(p => p.x !== -3)
        , this.props.tuinInhoudMapIndex);
    }
    handleClose() {
        this.setState({show: false});
    }

    handleShow(x: number, y: number) {
        this.setState({
            selectedX: x,
            selectedY: y
        })
        this.setState({show: true});
    }
    componentDidMount() {
        const width = this.myRef.current?.offsetWidth == null?100:this.myRef.current?.offsetWidth;
        this.setState({ containerwidth: width });
    }

    clickRemove() {
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure you want to remove that garden?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.props.removeGarden(this.props.tuinInhoudMapIndex)
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });

    }
    render() {
        return <CenteredGardenView data-cy={'garden_week_view_'+this.props.tuinInhoudMapIndex}  ref={this.myRef}>
            {
                this.props.tuinInhoudMap.getValuesForWeek(this.props.selectedWeek).map((row, rowIndex) => {
                        return <div data-cy="gardenrender" className={"row flex-nowrap justify-content-center"} key={rowIndex + ','+this.props.tuinInhoudMapIndex}>
                            {
                                row.map(element => <TuinBlokje
                                    gardenIndex={this.props.tuinInhoudMapIndex}
                                    language={this.props.language}
                                    blocksize={Math.min(200,(this.state.containerwidth - (2 * row.length))/row.length)}
                                    key={element.x + "," + element.y+ ',' + rowIndex + ',' + this.props.tuinInhoudMapIndex}
                                    x={element.x} y={element.y}
                                    inhoud={element}
                                    addSelection={this.addSelection}
                                />)
                            }
                        </div>
                    }
                )}
                <ButtonContainer>
                    <button onClick={this.clickRemove} data-cy={"garden_remove_" + this.props.tuinInhoudMapIndex} className={"btn btn-secondary btn-sm"}>x</button>
                </ButtonContainer>
                </CenteredGardenView>;

    }
}
