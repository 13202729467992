export class Translater {

    private static nl = new Map<string, string>([
        ["tuinplan_maken", "Nieuw tuinplan maken"],
        ["taal", "Taal"],
        ["breedte", "Breedte in cm"],
        ["hoogte", "Lengte in cm"],
        ["jaar", "Jaar"],
        ["blocksize", "Blokgrootte in cm"],
        ["apply", "Toepassen"],
        ["close", "Sluiten"],
        ["garden_on_year", "Tuin in het jaar"],
        ["weeks_in", "weken in"],
        ["januari", "januari"],
        ["februari", "februari"],
        ["maart", "maart"],
        ["april", "april"],
        ["mei", "mei"],
        ["juni", "juni"],
        ["juli", "juli"],
        ["augustus", "augustus"],
        ["september", "september"],
        ["oktober", "oktober"],
        ["november", "november"],
        ["december", "december"],
        ["save", "opslaan"],
        ["reset", "Nieuw plan"],
        ["year_overview", "Jaar overzicht"],
        ["plant_period", "Plant periode in weken"],
        ["harvest_period","Oogstperiode in weken"],
        ["distance_row", "rijafstand"],
        ["distance_col", "Afstand kolom"],
        ["time_till_harvest", "Tijd tot oogsten"],
        ["estimated_plants", "Geschat aantal planten in 1 blok"],
        ["add_garden", "Voeg tuin toe"],
        ["add_garden_title", "Tuin onderdeel toevoegen"]
    ]);
    private static en = new Map<string, string>([
        ["tuinplan_maken", "Create new garden plan"],
        ["taal", "Language"],
        ["breedte", "Width in feet"],
        ["hoogte", "Length in feet"],
        ["jaar", "Year"],
        ["blocksize", "block size in feet"],
        ["apply", "Apply"],
        ["close", "Close"],
        ["garden_on_year", "Garden on year"],
        ["weeks_in", "weeks in"],
        ["januari", "January"],
        ["februari", "February"],
        ["maart", "March"],
        ["april", "April"],
        ["mei", "May"],
        ["juni", "June"],
        ["juli", "July"],
        ["augustus", "August"],
        ["september", "September"],
        ["oktober", "October"],
        ["november", "November"],
        ["december", "December"],
        ["save", "Save"],
        ["reset", "Create new plan"],
        ["year_overview", "Year overview"],
        ["plant_period", "Plantperiod in weeks"],
        ["harvest_period","Harvest period in weeks"],
        ["distance_row", "Distance row"],
        ["distance_col", "Distance column"],
        ["time_till_harvest", "Time until harvest"],
        ["estimated_plants", "Estimated #plants in block"],
        ["add_garden", "Add garden"],
        ["add_garden_title", "Add additional garden"]
    ]);


    static translate(key : string, languagecode : string) :string {
        let ans = "?";
        let selectedMap : Map<string, string> = this.nl;
        switch (languagecode) {
            case "nl": selectedMap = this.nl; break;
            case "en": selectedMap = this.en; break;
            default: return "Unknown language";
        }

        const k : any  = selectedMap.get(key);
        if (k !== null) {
            ans = k;
        }
        return ans;
    }


}

