import carrot from "./images/carrot with viewbox.svg";
import cucumber from "./images/cucumber.svg";
import potato from "./images/potato.svg";
import paprika from "./images/paprika.svg";
import onkruid from "./images/onkruid.svg";
import pebbles from "./images/pebbles.svg";
import strawberry from "./images/strawberry.svg";
import lettuce from './images/lettuce.svg';
import pickle from './images/pickle.svg';
import cauliflower from './images/cauliflower.svg';
import beans from './images/beans.svg';
import broccoli from './images/broccoli.svg';
import cabbage from './images/cabbage.svg';
import turnip from './images/turnip.svg';
import courgette from './images/courgette.svg';
import celery from './images/celery.svg';
import kohlrabi from './images/kohlrabi.svg';
import pumpkin from './images/pumpkin.svg';
import radish from './images/radish.svg';
import leek from './images/leek.svg';
import onion from './images/onion.svg';
import brusselsprouts from './images/brussels-sprouts.svg';

//https://www.tuinadvies.be/artikels/groenten_zaaikalender

const plants = [
    {
        "name": "weeds",
        "startPlantSeasonWeekNr" : 1,
        "endPlantSeasonWeekNr" : 52,
        "startHarvestWeekNr" : 1,
        "endHarvestWeekNr" : 52,
        "distanceRow":"0",
        "distanceCol":"0",
        "harvesttimeWeeks": 1,
        "src": onkruid
    },
    {
        "name": "potato early",
        "startPlantSeasonWeekNr" : 9,
        "endPlantSeasonWeekNr" : 17,
        "startHarvestWeekNr" : 22,
        "endHarvestWeekNr" : 27,
        "distanceRow":"50",
        "distanceCol":"50",
        "harvesttimeWeeks": 11,
        "src": potato
    },
    {
        "name": "potato late",
        "startPlantSeasonWeekNr" : 13,
        "endPlantSeasonWeekNr" : 17,
        "startHarvestWeekNr" : 25, // != september - okt !!
        "endHarvestWeekNr" : 28,
        "distanceRow":"50",
        "distanceCol":"50",
        "harvesttimeWeeks": 11,
        "src": potato
    },
    {
        "attrib" : '<div>Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>',
        "name": "strawberry early",
        "startPlantSeasonWeekNr" : 13,
        "endPlantSeasonWeekNr" : 15,
        "startHarvestWeekNr" : 22,
        "endHarvestWeekNr" : 26,
        "distanceRow":80,
        "distanceCol":25,
        "harvesttimeWeeks": 7,
        "src": strawberry
    },
    {
        "name": "strawberry",
        "startPlantSeasonWeekNr" : 31,
        "endPlantSeasonWeekNr" : 34,
        "startHarvestWeekNr" : 22,
        "endHarvestWeekNr" : 26,
        "distanceRow":80,
        "distanceCol":25,
        "harvesttimeWeeks": 42,
        "src": strawberry
    },
    {
        "attrib": '<div>Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>',
        "name": "endive spring",
        "startPlantSeasonWeekNr" : 13,
        "endPlantSeasonWeekNr" : 15,
        "startHarvestWeekNr" : 22,
        "endHarvestWeekNr" : 26,
        "distanceRow":25,
        "distanceCol":25,
        "harvesttimeWeeks": 10,
        "src": lettuce
    },
    {
        "name": "endive summer",
        "startPlantSeasonWeekNr" : 18,
        "endPlantSeasonWeekNr" : 26,
        "startHarvestWeekNr" : 27,
        "endHarvestWeekNr" : 34,
        "distanceRow":30,
        "distanceCol":30,
        "harvesttimeWeeks": 8,
        "src": lettuce
    },
    {
        "name": "endive fall",
        "startPlantSeasonWeekNr" : 27,
        "endPlantSeasonWeekNr" : 39,
        "startHarvestWeekNr" : 35,
        "endHarvestWeekNr" : 43,
        "distanceRow":30,
        "distanceCol":30,
        "harvesttimeWeeks": 8,
        "src": lettuce
    },
    {
        "name": "pickles",
        "startPlantSeasonWeekNr" : 20,
        "endPlantSeasonWeekNr" : 26,
        "startHarvestWeekNr" : 27,
        "endHarvestWeekNr" : 39,
        "distanceRow":200,
        "distanceCol":50,
        "harvesttimeWeeks": 6,
        "src": pickle
    },
    {
        "name": "celery",
        "startPlantSeasonWeekNr" : 22,
        "endPlantSeasonWeekNr" : 26,
        "startHarvestWeekNr" : 35,
        "endHarvestWeekNr" : 39,
        "distanceRow":30,
        "distanceCol":30,
        "harvesttimeWeeks": 13,
        "src": lettuce
    },
    {
        "name": "cauliflower early",
        "startPlantSeasonWeekNr" : 9,
        "endPlantSeasonWeekNr" : 12,
        "startHarvestWeekNr" : 22,
        "endHarvestWeekNr" : 26,
        "distanceRow":60,
        "distanceCol":60,
        "harvesttimeWeeks": 9,
        "src": cauliflower
    },
    {
        "name": "cauliflower",
        "startPlantSeasonWeekNr" : 13,
        "endPlantSeasonWeekNr" : 17,
        "startHarvestWeekNr" : 22,
        "endHarvestWeekNr" : 30,
        "distanceRow":60,
        "distanceCol":60,
        "harvesttimeWeeks": 9,
        "src": cauliflower
    },
    {
        "name": "cauliflower late",
        "startPlantSeasonWeekNr" : 22,
        "endPlantSeasonWeekNr" : 30,
        "startHarvestWeekNr" : 31,
        "endHarvestWeekNr" : 39,
        "distanceRow":60,
        "distanceCol":60,
        "harvesttimeWeeks": 9,
        "src": cauliflower
    },
    {
        "name": "kale",
        "startPlantSeasonWeekNr" : 27,
        "endPlantSeasonWeekNr" : 30,
        "startHarvestWeekNr" : 40,
        "endHarvestWeekNr" : 52,
        "distanceRow":60,
        "distanceCol":50,
        "harvesttimeWeeks": 13,
        "src": lettuce
    },
    {
        "name": "beans",
        "startPlantSeasonWeekNr" : 20,
        "endPlantSeasonWeekNr" : 26,
        "startHarvestWeekNr" : 27,
        "endHarvestWeekNr" : 34,
        "distanceRow":50,
        "distanceCol":50,
        "harvesttimeWeeks": 7,
        "src": beans
    },
    {
        "name": "broccoli",
        "startPlantSeasonWeekNr" : 9,
        "endPlantSeasonWeekNr" : 30,
        "startHarvestWeekNr" : 22,
        "endHarvestWeekNr" : 48,
        "distanceRow":40,
        "distanceCol":45,
        "harvesttimeWeeks": 13,
        "src": broccoli
    },
    {
        "name": "chinese cabbage",
        "startPlantSeasonWeekNr" : 9,
        "endPlantSeasonWeekNr" : 34,
        "startHarvestWeekNr" : 18,
        "endHarvestWeekNr" : 48,
        "distanceRow":40,
        "distanceCol":45,
        "harvesttimeWeeks": 13,
        "src": cabbage
    },
    {
        "name": "turnips",
        "startPlantSeasonWeekNr" : 9,
        "endPlantSeasonWeekNr" : 34,
        "startHarvestWeekNr" : 18,
        "endHarvestWeekNr" : 34,
        "distanceRow":40,
        "distanceCol":30,
        "harvesttimeWeeks": 13,
        "src": turnip
    },
    {
        "name": "courgette",
        "startPlantSeasonWeekNr" : 18,
        "endPlantSeasonWeekNr" : 26,
        "startHarvestWeekNr" : 26,
        "endHarvestWeekNr" : 43,
        "distanceRow":100,
        "distanceCol":100,
        "harvesttimeWeeks": 8,
        "src": courgette
    },
    {
        "attrib": '<div>Icons made by <a href="https://www.flaticon.com/authors/monkik" title="monkik">monkik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>',
        "name": "celeriac",
        "startPlantSeasonWeekNr" : 18,
        "endPlantSeasonWeekNr" : 26,
        "startHarvestWeekNr" : 35,
        "endHarvestWeekNr" : 43,
        "distanceRow":50,
        "distanceCol":40,
        "harvesttimeWeeks": 17,
        "src": celery
    },
    {
        "name": "kohlrabi",
        "startPlantSeasonWeekNr" : 9,
        "endPlantSeasonWeekNr" : 34,
        "startHarvestWeekNr" : 18,
        "endHarvestWeekNr" : 43,
        "distanceRow":30,
        "distanceCol":30,
        "harvesttimeWeeks": 9,
        "src": kohlrabi
    },
    {
        "name": "parsley",
        "startPlantSeasonWeekNr" : 18,
        "endPlantSeasonWeekNr" : 21,
        "startHarvestWeekNr" : 18,
        "endHarvestWeekNr" : 43,
        "distanceRow":20,
        "distanceCol":5,
        "harvesttimeWeeks": 9,
        "src": celery
    },
    {
        "name": "pumpkin",
        "startPlantSeasonWeekNr" : 18,
        "endPlantSeasonWeekNr" : 26,
        "startHarvestWeekNr" : 31,
        "endHarvestWeekNr" : 39,
        "distanceRow":250,
        "distanceCol":80,
        "harvesttimeWeeks": 9,
        "src": pumpkin
    },
    {
        "name": "leek",
        "startPlantSeasonWeekNr" : 18,
        "endPlantSeasonWeekNr" : 26,
        "startHarvestWeekNr" : 22,
        "endHarvestWeekNr" : 56, //door de winter.
        "distanceRow":40,
        "distanceCol":15,
        "harvesttimeWeeks": 4,
        "src": leek
    },
    {
        "name": "radish",
        "startPlantSeasonWeekNr" : 9,
        "endPlantSeasonWeekNr" : 17,
        "startHarvestWeekNr" : 22,
        "endHarvestWeekNr" : 43,
        "distanceRow":25,
        "distanceCol":25,
        "harvesttimeWeeks": 13,
        "src": radish
    },
    {
        "name": "red cabbage",
        "startPlantSeasonWeekNr" : 13,
        "endPlantSeasonWeekNr" : 30,
        "startHarvestWeekNr" : 22,
        "endHarvestWeekNr" : 48,
        "distanceRow":60,
        "distanceCol":50,
        "harvesttimeWeeks": 18,
        "src": cabbage
    },
    {
        "name": "savoy cabbage",
        "startPlantSeasonWeekNr" : 22,
        "endPlantSeasonWeekNr" : 30,
        "startHarvestWeekNr" : 35,
        "endHarvestWeekNr" : 48,
        "distanceRow":50,
        "distanceCol":50,
        "harvesttimeWeeks": 13,
        "src": cabbage
    },
    {
        "name": "shallots",
        "startPlantSeasonWeekNr" : 9,
        "endPlantSeasonWeekNr" : 17,
        "startHarvestWeekNr" : 27,
        "endHarvestWeekNr" : 30,
        "distanceRow":20,
        "distanceCol":10,
        "harvesttimeWeeks": 19,
        "src": onion
    },
    {
        "name": "crop",
        "startPlantSeasonWeekNr" : 9,
        "endPlantSeasonWeekNr" : 39,
        "startHarvestWeekNr" : 18,
        "endHarvestWeekNr" : 43,
        "distanceRow":25,
        "distanceCol":25,
        "harvesttimeWeeks": 9,
        "src": cabbage
    },
    {
        "name": "sprouts",
        "startPlantSeasonWeekNr" : 13,
        "endPlantSeasonWeekNr" : 26,
        "startHarvestWeekNr" : 35,
        "endHarvestWeekNr" : 64,
        "distanceRow":70,
        "distanceCol":60,
        "harvesttimeWeeks": 9,
        "src": brusselsprouts
    },
    {
        "name": "paprika",
        "startPlantSeasonWeekNr" : 9,
        "endPlantSeasonWeekNr" : 17,
        "startHarvestWeekNr" : 27,
        "endHarvestWeekNr" : 43,
        "distanceRow":40,
        "distanceCol":50,
        "harvesttimeWeeks": 18,
        "src": paprika
    },
    {
        "name": "onion",
        "startPlantSeasonWeekNr" : 9,
        "endPlantSeasonWeekNr" : 17,
        "startHarvestWeekNr" : 27,
        "endHarvestWeekNr" : 34,
        "distanceRow":20,
        "distanceCol":10,
        "harvesttimeWeeks": 18,
        "src": onion
    },

    {
        "name": "white cabbage",
        "startPlantSeasonWeekNr" : 9,
        "endPlantSeasonWeekNr" : 17,
        "startHarvestWeekNr" : 35,
        "endHarvestWeekNr" : 48,
        "distanceRow":70,
        "distanceCol":70,
        "harvesttimeWeeks": 26,
        "src": cabbage
    },

    {
        "name": "path",
        "startPlantSeasonWeekNr" : 1,
        "endPlantSeasonWeekNr" : 52,
        "startHarvestWeekNr" : 1,
        "endHarvestWeekNr" : 12,
        "distanceRow":"0",
        "distanceCol":"0",
        "harvesttimeWeeks": 52,
        "src": pebbles
    },
    {
        "name": "carrot summer",
        "startPlantSeasonWeekNr" : 16,
        "endPlantSeasonWeekNr" : 30,
        "startHarvestWeekNr" : 27,
        "endHarvestWeekNr" : 48,
        "distanceRow":"30",
        "distanceCol":"30",
        "harvesttimeWeeks": 12,
        "src": carrot
    },
    {
        "name": "cucumber",
        "startPlantSeasonWeekNr" : 18,
        "endPlantSeasonWeekNr" : 26,
        "startHarvestWeekNr" : 27,
        "endHarvestWeekNr" : 39,
        "distanceRow":80,
        "distanceCol":100,
        "harvesttimeWeeks": 18,
        "src": cucumber
    },
    {
        "name": "garlic",
        "startPlantSeasonWeekNr" : 5,
        "endPlantSeasonWeekNr" : 17,
        "startHarvestWeekNr" : 27,
        "endHarvestWeekNr" : 39,
        "distanceRow":8,
        "distanceCol":30,
        "harvesttimeWeeks": 22,
        "src": onion
    },
];



const planten = [
    {
        "name": "onkruid",
        "startPlantSeasonWeekNr" : 1,
        "endPlantSeasonWeekNr" : 52,
        "startHarvestWeekNr" : 1,
        "endHarvestWeekNr" : 52,
        "distanceRow":"0",
        "distanceCol":"0",
        "harvesttimeWeeks": 1,
        "src": onkruid
    },
    {
        "name": "aardappelen vroeg",
        "startPlantSeasonWeekNr" : 9,
        "endPlantSeasonWeekNr" : 17,
        "startHarvestWeekNr" : 22,
        "endHarvestWeekNr" : 27,
        "distanceRow":"50",
        "distanceCol":"50",
        "harvesttimeWeeks": 11,
        "src": potato
    },
    {
        "name": "aardappelen laat",
        "startPlantSeasonWeekNr" : 13,
        "endPlantSeasonWeekNr" : 17,
        "startHarvestWeekNr" : 25, // != september - okt !!
        "endHarvestWeekNr" : 28,
        "distanceRow":"50",
        "distanceCol":"50",
        "harvesttimeWeeks": 11,
        "src": potato
    },
    {
        "attrib" : '<div>Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>',
        "name": "aardbeien vroeg",
        "startPlantSeasonWeekNr" : 13,
        "endPlantSeasonWeekNr" : 15,
        "startHarvestWeekNr" : 22,
        "endHarvestWeekNr" : 26,
        "distanceRow":80,
        "distanceCol":25,
        "harvesttimeWeeks": 7,
        "src": strawberry
    },
    {
        "name": "aardbeien",
        "startPlantSeasonWeekNr" : 31,
        "endPlantSeasonWeekNr" : 34,
        "startHarvestWeekNr" : 22,
        "endHarvestWeekNr" : 26,
        "distanceRow":80,
        "distanceCol":25,
        "harvesttimeWeeks": 42,
        "src": strawberry
    },
    {
        "attrib": '<div>Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>',
        "name": "andijvie lente",
        "startPlantSeasonWeekNr" : 13,
        "endPlantSeasonWeekNr" : 15,
        "startHarvestWeekNr" : 22,
        "endHarvestWeekNr" : 26,
        "distanceRow":25,
        "distanceCol":25,
        "harvesttimeWeeks": 10,
        "src": lettuce
    },
    {
        "name": "andijvie zomer",
        "startPlantSeasonWeekNr" : 18,
        "endPlantSeasonWeekNr" : 26,
        "startHarvestWeekNr" : 27,
        "endHarvestWeekNr" : 34,
        "distanceRow":30,
        "distanceCol":30,
        "harvesttimeWeeks": 8,
        "src": lettuce
    },
    {
        "name": "andijvie herfst",
        "startPlantSeasonWeekNr" : 27,
        "endPlantSeasonWeekNr" : 39,
        "startHarvestWeekNr" : 35,
        "endHarvestWeekNr" : 43,
        "distanceRow":30,
        "distanceCol":30,
        "harvesttimeWeeks": 8,
        "src": lettuce
    },
    {
        "name": "augurken",
        "startPlantSeasonWeekNr" : 20,
        "endPlantSeasonWeekNr" : 26,
        "startHarvestWeekNr" : 27,
        "endHarvestWeekNr" : 39,
        "distanceRow":200,
        "distanceCol":50,
        "harvesttimeWeeks": 6,
        "src": pickle
    },
    {
        "name": "bleekselder",
        "startPlantSeasonWeekNr" : 22,
        "endPlantSeasonWeekNr" : 26,
        "startHarvestWeekNr" : 35,
        "endHarvestWeekNr" : 39,
        "distanceRow":30,
        "distanceCol":30,
        "harvesttimeWeeks": 13,
        "src": lettuce
    },
    {
        "name": "bloemkolen vroeg",
        "startPlantSeasonWeekNr" : 9,
        "endPlantSeasonWeekNr" : 12,
        "startHarvestWeekNr" : 22,
        "endHarvestWeekNr" : 26,
        "distanceRow":60,
        "distanceCol":60,
        "harvesttimeWeeks": 9,
        "src": cauliflower
    },
    {
        "name": "bloemkolen",
        "startPlantSeasonWeekNr" : 13,
        "endPlantSeasonWeekNr" : 17,
        "startHarvestWeekNr" : 22,
        "endHarvestWeekNr" : 30,
        "distanceRow":60,
        "distanceCol":60,
        "harvesttimeWeeks": 9,
        "src": cauliflower
    },
    {
        "name": "bloemkolen laat",
        "startPlantSeasonWeekNr" : 22,
        "endPlantSeasonWeekNr" : 30,
        "startHarvestWeekNr" : 31,
        "endHarvestWeekNr" : 39,
        "distanceRow":60,
        "distanceCol":60,
        "harvesttimeWeeks": 9,
        "src": cauliflower
    },
    {
        "name": "boerenkolen",
        "startPlantSeasonWeekNr" : 27,
        "endPlantSeasonWeekNr" : 30,
        "startHarvestWeekNr" : 40,
        "endHarvestWeekNr" : 52,
        "distanceRow":60,
        "distanceCol":50,
        "harvesttimeWeeks": 13,
        "src": lettuce
    },
    {
        "name": "boontjes",
        "startPlantSeasonWeekNr" : 20,
        "endPlantSeasonWeekNr" : 26,
        "startHarvestWeekNr" : 27,
        "endHarvestWeekNr" : 34,
        "distanceRow":50,
        "distanceCol":50,
        "harvesttimeWeeks": 7,
        "src": beans
    },
    {
        "name": "doperwten",
        "startPlantSeasonWeekNr" : 9,
        "endPlantSeasonWeekNr" : 22,
        "startHarvestWeekNr" : 23,
        "endHarvestWeekNr" : 35,
        "distanceRow":4,
        "distanceCol":30,
        "harvesttimeWeeks": 14,
        "src": beans
    },
    {
        "name": "broccoli",
        "startPlantSeasonWeekNr" : 9,
        "endPlantSeasonWeekNr" : 30,
        "startHarvestWeekNr" : 22,
        "endHarvestWeekNr" : 48,
        "distanceRow":40,
        "distanceCol":45,
        "harvesttimeWeeks": 13,
        "src": broccoli
    },
    {
        "name": "chinese kool",
        "startPlantSeasonWeekNr" : 9,
        "endPlantSeasonWeekNr" : 34,
        "startHarvestWeekNr" : 18,
        "endHarvestWeekNr" : 48,
        "distanceRow":40,
        "distanceCol":45,
        "harvesttimeWeeks": 13,
        "src": cabbage
    },
    {
        "name": "rapen",
        "startPlantSeasonWeekNr" : 9,
        "endPlantSeasonWeekNr" : 34,
        "startHarvestWeekNr" : 18,
        "endHarvestWeekNr" : 34,
        "distanceRow":40,
        "distanceCol":30,
        "harvesttimeWeeks": 13,
        "src": turnip
    },
    {
        "name": "courgette",
        "startPlantSeasonWeekNr" : 18,
        "endPlantSeasonWeekNr" : 26,
        "startHarvestWeekNr" : 26,
        "endHarvestWeekNr" : 43,
        "distanceRow":100,
        "distanceCol":100,
        "harvesttimeWeeks": 8,
        "src": courgette
    },
    {
        "attrib": '<div>Icons made by <a href="https://www.flaticon.com/authors/monkik" title="monkik">monkik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>',
        "name": "knolselder",
        "startPlantSeasonWeekNr" : 18,
        "endPlantSeasonWeekNr" : 26,
        "startHarvestWeekNr" : 35,
        "endHarvestWeekNr" : 43,
        "distanceRow":50,
        "distanceCol":40,
        "harvesttimeWeeks": 17,
        "src": celery
    },
    {
        "name": "koolrabi",
        "startPlantSeasonWeekNr" : 9,
        "endPlantSeasonWeekNr" : 34,
        "startHarvestWeekNr" : 18,
        "endHarvestWeekNr" : 43,
        "distanceRow":30,
        "distanceCol":30,
        "harvesttimeWeeks": 9,
        "src": kohlrabi
    },
    {
        "name": "peterselie",
        "startPlantSeasonWeekNr" : 18,
        "endPlantSeasonWeekNr" : 21,
        "startHarvestWeekNr" : 18,
        "endHarvestWeekNr" : 43,
        "distanceRow":20,
        "distanceCol":5,
        "harvesttimeWeeks": 9,
        "src": celery
    },
    {
        "name": "pompoenen",
        "startPlantSeasonWeekNr" : 18,
        "endPlantSeasonWeekNr" : 26,
        "startHarvestWeekNr" : 31,
        "endHarvestWeekNr" : 39,
        "distanceRow":250,
        "distanceCol":80,
        "harvesttimeWeeks": 9,
        "src": pumpkin
    },
    {
        "name": "prei",
        "startPlantSeasonWeekNr" : 18,
        "endPlantSeasonWeekNr" : 26,
        "startHarvestWeekNr" : 22,
        "endHarvestWeekNr" : 56, //door de winter.
        "distanceRow":40,
        "distanceCol":15,
        "harvesttimeWeeks": 4,
        "src": leek
    },
    {
        "name": "rammenas",
        "startPlantSeasonWeekNr" : 9,
        "endPlantSeasonWeekNr" : 17,
        "startHarvestWeekNr" : 22,
        "endHarvestWeekNr" : 43,
        "distanceRow":25,
        "distanceCol":25,
        "harvesttimeWeeks": 13,
        "src": radish
    },
    {
        "name": "rode kolen",
        "startPlantSeasonWeekNr" : 13,
        "endPlantSeasonWeekNr" : 30,
        "startHarvestWeekNr" : 22,
        "endHarvestWeekNr" : 48,
        "distanceRow":60,
        "distanceCol":50,
        "harvesttimeWeeks": 18,
        "src": cabbage
    },
    {
        "name": "radijs",
        "startPlantSeasonWeekNr" : 9,
        "endPlantSeasonWeekNr" : 17,
        "startHarvestWeekNr" : 22,
        "endHarvestWeekNr" : 43,
        "distanceRow":25,
        "distanceCol":25,
        "harvesttimeWeeks": 13,
        "src": radish
    },
    {
        "name": "savooikolen",
        "startPlantSeasonWeekNr" : 22,
        "endPlantSeasonWeekNr" : 30,
        "startHarvestWeekNr" : 35,
        "endHarvestWeekNr" : 48,
        "distanceRow":50,
        "distanceCol":50,
        "harvesttimeWeeks": 13,
        "src": cabbage
    },
    {
        "name": "sjalotten",
        "startPlantSeasonWeekNr" : 9,
        "endPlantSeasonWeekNr" : 17,
        "startHarvestWeekNr" : 27,
        "endHarvestWeekNr" : 30,
        "distanceRow":20,
        "distanceCol":10,
        "harvesttimeWeeks": 19,
        "src": onion
    },
    {
        "name": "kropsla",
        "startPlantSeasonWeekNr" : 9,
        "endPlantSeasonWeekNr" : 39,
        "startHarvestWeekNr" : 18,
        "endHarvestWeekNr" : 43,
        "distanceRow":25,
        "distanceCol":25,
        "harvesttimeWeeks": 9,
        "src": cabbage
    },
    {
        "name": "spruiten",
        "startPlantSeasonWeekNr" : 13,
        "endPlantSeasonWeekNr" : 26,
        "startHarvestWeekNr" : 35,
        "endHarvestWeekNr" : 64,
        "distanceRow":70,
        "distanceCol":60,
        "harvesttimeWeeks": 9,
        "src": brusselsprouts
    },
    {
        "name": "uien",
        "startPlantSeasonWeekNr" : 9,
        "endPlantSeasonWeekNr" : 17,
        "startHarvestWeekNr" : 27,
        "endHarvestWeekNr" : 34,
        "distanceRow":20,
        "distanceCol":10,
        "harvesttimeWeeks": 18,
        "src": onion
    },
    {
        "name": "witte kolen",
        "startPlantSeasonWeekNr" : 9,
        "endPlantSeasonWeekNr" : 17,
        "startHarvestWeekNr" : 35,
        "endHarvestWeekNr" : 48,
        "distanceRow":70,
        "distanceCol":70,
        "harvesttimeWeeks": 26,
        "src": cabbage
    },
    {
        "name": "pad",
        "startPlantSeasonWeekNr" : 1,
        "endPlantSeasonWeekNr" : 52,
        "startHarvestWeekNr" : 1,
        "endHarvestWeekNr" : 12,
        "distanceRow":"0",
        "distanceCol":"0",
        "harvesttimeWeeks": 52,
        "src": pebbles
    },
    {
        "name": "zomer wortel",
        "startPlantSeasonWeekNr" : 16,
        "endPlantSeasonWeekNr" : 30,
        "startHarvestWeekNr" : 27,
        "endHarvestWeekNr" : 48,
        "distanceRow":"30",
        "distanceCol":"30",
        "harvesttimeWeeks": 12,
        "src": carrot
    },
    {
        "name": "paprika",
        "startPlantSeasonWeekNr" : 9,
        "endPlantSeasonWeekNr" : 17,
        "startHarvestWeekNr" : 27,
        "endHarvestWeekNr" : 43,
        "distanceRow":40,
        "distanceCol":50,
        "harvesttimeWeeks": 18,
        "src": paprika
    },
    {
        "name": "komkommer",
        "startPlantSeasonWeekNr" : 18,
        "endPlantSeasonWeekNr" : 26,
        "startHarvestWeekNr" : 27,
        "endHarvestWeekNr" : 39,
        "distanceRow":80,
        "distanceCol":100,
        "harvesttimeWeeks": 18,
        "src": cucumber
    },
    {
        "name": "look",
        "startPlantSeasonWeekNr" : 5,
        "endPlantSeasonWeekNr" : 17,
        "startHarvestWeekNr" : 27,
        "endHarvestWeekNr" : 39,
        "distanceRow":8,
        "distanceCol":30,
        "harvesttimeWeeks": 22,
        "src": onion
    },
    {
        "name": "spinazie",
        "startPlantSeasonWeekNr" : 11,
        "endPlantSeasonWeekNr" : 26,
        "startHarvestWeekNr" : 13,
        "endHarvestWeekNr" : 30,
        "distanceRow":8,
        "distanceCol":25,
        "harvesttimeWeeks": 4,
        "src": cabbage
    },

];

class Plant
{
    public name : string;
    public startPlantSeasonWeekNr : number;
    public endPlantSeasonWeekNr : number;
    public startHarvestWeekNr : number;
    public endHarvestWeekNr : number;
    public distanceRow:number;
    public distanceCol:number;
    public harvesttimeWeeks: number;
    public src: string;

    public calculateCropCount(blocksize: number) {
            return Math.floor(blocksize/this.distanceRow) * Math.floor(blocksize/this.distanceCol);
    }

    constructor(json:any) {
        this.name = json.name;
        this.startPlantSeasonWeekNr =  json.startPlantSeasonWeekNr;
        this.endPlantSeasonWeekNr   =  json.endPlantSeasonWeekNr  ;
        this.startHarvestWeekNr     =  json.startHarvestWeekNr    ;
        this.endHarvestWeekNr       =  json.endHarvestWeekNr      ;
        this.distanceRow            =  json.distanceRow           ;
        this.distanceCol            =  json.distanceCol           ;
        this.harvesttimeWeeks       =  json.harvesttimeWeeks      ;
        this.src                    =  json.src                   ;
    }
}

export class PlantSource {
    static getAllPlants(language : string): Plant[] {
        return this.getPlantArr(language).sort((n1, n2) => {
        if (n1.name > n2.name) {
            return 1;
        }

        if (n1.name < n2.name) {
            return -1;
        }

        return 0;}
        ).map(p => new Plant(p));
    }

    static getPlantArr(language: string) {
        switch (language) {
            case "nl" : return planten;
            case "en" : return plants;
            default:
                return planten;
        }
    }
    static getPlantByName(selectedCrop:string, language :string) : Plant
    {
        const plants = this.getPlantArr(language);
        if (plants.filter(p => p.name === selectedCrop).length !== 1) {
            throw new Error("Cannot find crop by name " + selectedCrop);
        }
        return new Plant(plants.filter(p => p.name === selectedCrop)[0]);
    }

    static getPlantsForWeek(weekNr:number, language: string) : Plant[]
    {
        const pl = this.getPlantArr(language);
        return pl.filter(p => p.startPlantSeasonWeekNr <= weekNr &&
                    p.endPlantSeasonWeekNr >= weekNr).map(p => new Plant(p));
    }
}