import React from "react";
import {Button, Modal} from "react-bootstrap";
import InputNumber from "rc-input-number";
import {Translater} from "./Translater";

interface AddGardenProps {
    addGardenData : (breedte : number, hoogte : number) => void;
    blocksize: number,
    language: string
}

interface AddGardenState {
    breedte : number,
    hoogte : number, showReset : boolean
}

export class AddGarden extends React.Component<AddGardenProps, AddGardenState>
{
    constructor(props: AddGardenProps) {
        super(props);
        this.state = {
            breedte : 1000,
            hoogte : 200,
            showReset : false
        }
        this.handleCloseReset = this.handleCloseReset.bind(this);
        this.addGardenData = this.addGardenData.bind(this);
        this.handleShowReset = this.handleShowReset.bind(this);
        this.onChangeBreedte = this.onChangeBreedte.bind(this);
        this.onChangeHoogte = this.onChangeHoogte.bind(this);
    }

    handleCloseReset() {
        this.setState({showReset:false});
    }


    addGardenData() {
        this.props.addGardenData(Math.ceil(this.state.breedte /this.props.blocksize), Math.ceil(this.state.hoogte/this.props.blocksize));
        this.handleCloseReset();
    }
    handleShowReset() {
        this.setState({showReset:true});
    }

    onChangeBreedte (value : string | number | undefined) {
        if (typeof value == "number") {
            this.setState({ breedte : value });
        }
        if (typeof value == "string") {
            this.setState({ breedte : parseInt(value)});
        }
    }

    onChangeHoogte (value : string | number | undefined) {
        if (typeof value == "number") {
            this.setState({ hoogte : value });
        }
        if (typeof value == "string") {
            this.setState({ hoogte : parseInt(value) });
        }
    }

    render() {
        return <React.Fragment><Modal animation={false} show={this.state.showReset} aria-labelledby="contained-modal-title-vcenter" onHide={this.handleCloseReset}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    { Translater.translate("add_garden_title", this.props.language) }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid">
                <label>{ Translater.translate("breedte", this.props.language) } <InputNumber
                    data-cy={"breedte"}
                    value={this.state.breedte}
                    min={1}
                    max={26800}
                    onChange={this.onChangeBreedte}/></label>&nbsp;
                <label>{ Translater.translate("hoogte", this.props.language) } <InputNumber
                    data-cy={"hoogte"}
                    value={this.state.hoogte}
                    min={1}
                    max={26800}
                    onChange={this.onChangeHoogte}/></label><br/>
            </Modal.Body>
            <Modal.Footer>
                <Button data-cy={"apply-garden"} onClick={this.addGardenData}>{ Translater.translate("apply", this.props.language) }</Button>
                <Button onClick={this.handleCloseReset} data-cy={"closeDetailsReset"}>{ Translater.translate("close", this.props.language) }</Button>
            </Modal.Footer>
        </Modal>
            <Button className={"btn btn-secondary btn-sm"} data-cy={'add-garden'} onClick={this.handleShowReset}>{Translater.translate('add_garden', this.props.language)}</Button>
        </React.Fragment>
    }
}