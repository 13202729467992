export class Point {

    constructor(x: number, y : number, gardenIndex : number) {
        this.x = x;
        this.y = y;
        this.gardenIndex = gardenIndex;
    }

    x: number;
    y: number;
    gardenIndex: number;
}