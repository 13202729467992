import {TuinInhoud} from "./TuinInhoud";

export class TuinInhoudMap
{
    private _width: number;
    private _height: number;

    private inhoud: TuinInhoud[][][];
    static fromString(str : string) : TuinInhoudMap
    {
        var inh = JSON.parse(str) as any[][][];
        var map = new TuinInhoudMap(inh[0][0].length, inh[0].length);
        map.setMap(inh.map(v1 => v1.map(v2 => v2.map(v3 => new TuinInhoud(v3._type, v3._x, v3._y)))));
        return map;
    }
    constructor(width: number, height: number) {
        this._width = width;
        this._height = height;
        this.inhoud = [];
        for (let week = 0; week <= 52; week++)
        {
            let weekArr = [];
            for (let y = 1; y <= height; y++) {
                let rij:  TuinInhoud[] = [];
                for (let x = 1; x <= width; x++) {
                    rij.push(new TuinInhoud("onkruid", x,y));
                }
                weekArr.push(rij);
            }
            this.inhoud.push(weekArr);
        }
    }

    asJson(): string {
        return JSON.stringify(this.inhoud);
    }

    setMap(inhoud: TuinInhoud[][][]) {
        this.inhoud = inhoud;
    }

    getValuesForWeek(weekIndex : number) {
        return this.inhoud[weekIndex];
    }
    getValuesForYear() {
        let sumCombined = [];
        for (let y = 0; y < this.inhoud[1].length; y++)
        {
            let row = [];
            for (let x = 0; x < this.inhoud[1][1].length; x++)
            {
                let type = "onkruid";
                for (let weeks = 1; weeks <= 52; weeks++) {
                    let typ = this.inhoud[weeks][y][x].type;
                    if (typ !== "onkruid") {
                        type = typ;
                    }
                }
                row.push(new TuinInhoud(type, x + 1, y + 1));
            }
            sumCombined.push(row);
        }
        return sumCombined;
    }

    withValuesAdjusted(selectedWeekIndex: number, crop: string, x: number, y: number, weekduration : number) {
        let copy = new TuinInhoudMap(this._width, this._height);
        copy.setMap(this.inhoud);
        for (let during = 0; during <= weekduration; during++)
            copy.inhoud[Math.min(52, during + selectedWeekIndex)][y - 1 ][x - 1].type = crop;
        return copy;
    }
    get width(): number {
        return this._width;
    }
    get height(): number {
        return this._height;
    }

}