import React from "react";
import {PlantSource} from "./PlantSource";
import {Button, Form} from "react-bootstrap";
import styled from "styled-components";
import {Translater} from "./Translater";

const PlantSelectorContainer = styled.div`
    margin-top: 10px;
    border: 1px solid grey;
    border-radius: 5px;
    width: 100%;
    padding:10px;
    background-color: rgba(184, 83, 45, 0.6)
`;

const DetailParagraph = styled.div`
    margin:5px;
    color:white;
    margin-top: 0px;
    margin-bottom: 0px;
    `;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
`;
const ApplyBtn = styled.div`

`;

interface PlantenSelectorProps {
    setSelectedCrop : (selectedCrop: string, weekduration: number) => void,
    selectedMonthIndex : number,
    blockSize: number, language: string
}

interface PlantenSelectorState {
    selectedPlant: string, selectedWeeks : number
}


export class PlantenSelector extends React.PureComponent<PlantenSelectorProps, PlantenSelectorState>
{
    private readonly myRef : React.RefObject<HTMLDivElement>;
    constructor(props: PlantenSelectorProps) {
        super(props);
        this.state = {
            selectedPlant: PlantSource.getAllPlants(this.props.language)[0].name,
            selectedWeeks : PlantSource.getAllPlants(this.props.language)[0].harvesttimeWeeks
        };
        this.changeOption = this.changeOption.bind(this);
        this.apply = this.apply.bind(this);
        this.onChangeWeek = this.onChangeWeek.bind(this);
        this.myRef = React.createRef();
    }

    changeOption(event: React.ChangeEvent<HTMLInputElement>) {
        let cropName = event.target.value;
        this.setState({selectedPlant: cropName, selectedWeeks : PlantSource.getPlantByName(cropName, this.props.language).harvesttimeWeeks});
    }

    apply() {
        let cropName = this.state.selectedPlant;
        this.props.setSelectedCrop(cropName, this.state.selectedWeeks);
        window.dispatchEvent(new Event('clearcheckboxes'));
    }

    onChangeWeek(event: React.ChangeEvent<HTMLInputElement>) {
        let week = parseInt(event.target.value);
        this.setState({ selectedWeeks : week });
    }

    render() {
        try {
            var selectedPlant = PlantSource.getPlantByName(this.state.selectedPlant, this.props.language);
        } catch (e) {
            selectedPlant = PlantSource.getAllPlants(this.props.language)[0];
        }
        return <footer className={"navbar fixed-bottom"} >
            <PlantSelectorContainer ref={this.myRef}>
            <Form.Control as="select" data-cy="cropSelector" onChange={this.changeOption}>
                {
                    PlantSource.getAllPlants(this.props.language)
                    .map((p) => {
                        return <option key={p.name} value={p.name}>{p.name}</option>;
                    })
                }
            </Form.Control>
            <div>
                <div className={"row"}>
                    <Column>
                        <DetailParagraph data-cy={"plantperiode"}>{Translater.translate("plant_period", this.props.language)}: { selectedPlant.startPlantSeasonWeekNr } - { selectedPlant.endPlantSeasonWeekNr}
                        </DetailParagraph>
                        <DetailParagraph data-cy={"distanceRow"}>{Translater.translate("distance_row", this.props.language)}: { selectedPlant.distanceRow }</DetailParagraph>
                        <DetailParagraph>{Translater.translate("time_till_harvest", this.props.language)}: &nbsp;
                            <input className={'d-inline-block'} data-cy={"harvesttimeWeeks"} onChange={this.onChangeWeek} type={"number"} value={ this.state.selectedWeeks }/>
                        </DetailParagraph>
                    </Column>
                    <Column>
                        <DetailParagraph data-cy={"oogstperiode"}>{Translater.translate("harvest_period", this.props.language)}: { selectedPlant.startHarvestWeekNr } - { selectedPlant.endHarvestWeekNr }</DetailParagraph>
                        <DetailParagraph data-cy={"distanceCol"}>{Translater.translate("distance_col", this.props.language)}: { selectedPlant.distanceCol }</DetailParagraph>
                        <DetailParagraph data-cy={"harvestcount"}>{Translater.translate("estimated_plants", this.props.language)}: { selectedPlant.calculateCropCount(this.props.blockSize) }
                        </DetailParagraph>
                    </Column>
                </div>
            </div>
                <ApplyBtn>
                    <Button className={"btn btn-primary"} data-cy={"apply-crop"} onClick={this.apply}>{Translater.translate("apply", this.props.language)}</Button>
                </ApplyBtn>
            </PlantSelectorContainer>
        </footer>
    }
}