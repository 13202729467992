export class TuinInhoud {
    get y() {
        return this._y;
    }

    set y(value) {
        this._y = value;
    }
    get x() {
        return this._x;
    }

    set x(value) {
        this._x = value;
    }
    constructor(type:string, x: number, y : number) {
        this._type = type;
        this._x = x;
        this._y = y;
    }
    get type(): string {
        return this._type;
    }

    set type(value: string) {
        this._type = value;
    }
    private _type : string
    private _x : number;
    private _y : number;

}
